// import Vue from "vue";
// import VueCookies from "vue-cookies";
import {createStore} from "vuex";
import * as Parser from "../util/ObjectParse";
import router from "../util/Routes.js";
import {
  POSTRequestJsonResponse,
  GETRequestJsonResponse,
  callLogin
} from "../util/apiCalls";
import createPersistedState from 'vuex-persistedstate';


Parser;

function getDefaultState(){
  return{
    authToken:"",
    loggedIn:false,
    showLoginError:false,
    recordSessions:[],
    recordSessionOffset:0,
    loggingOut:false,
    routerHistory:[],
    selectedRequestType:{ label: 'Email', value: 0, icon: ['fas','envelope'] },
    requestTypes: [
      { label: 'Email', value: 0, icon: ['fas','envelope']  },
      { label: 'Social Media Post', value: 1, icon: ['fas','hashtag'] },
      { label: 'Blog Post', value: 2, icon: ['fas','blog'] },
      { label: 'Cited Chat', value: 3, icon: ['fas','comment-alt']},
      { label: 'Web Chat', value: 4, icon: ['fas','search']}
    ],
    addedRecord:null,
    largeModel:true,
    chatTopicId:"0bcb40ac-8daf-475a-8c25-650ab5d45fd7",
    newSession:true,
    modelConversation:[],
    historicSelected:{},
  }
}


const store = createStore({
  state:getDefaultState, 
  //////////////////////////////!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!   LOOK AT IF WE WANT THIS IS PLACE FOR THE FULL APP 
  plugins:[
    // createPersistedState({
    //   getState:(key)=>Cookies.getJSON(key),
    //   setState:(key)=> Cookies.set(key,this.Store.state,{expires:3,secure:true})
    // })
    createPersistedState(),
  ],
  mutations: {
    reset(state){
      const s = getDefaultState();
      let hist=state.routerHistory; //? this is done to maintane the history across resets
      Object.keys(s).forEach(key=>{
        state[key]=s[key];
      });
      state.routerHistory=hist;
    },
    setAddedRecord(state,record){
      state.addedRecord=record;
    },
    setHistoricSelected(state,histSelect){
      state.historicSelected=histSelect
    },
    setModelConversation(state,conv){
      state.modelConversation=conv
    },
    setNewSession(state,newsession){
      state.newSession=newsession;
    },
    setLoggedIn(state, logged) {
      state.loggedIn = logged;
    },
    setShowLoginError(state,show)
    {
      state.showLoginError=show;
    },
    addRecordSessions(state,sessions){
      state.recordSessions=state.recordSessions.concat(sessions);
    },
    insertRecordSession(state,requestInfo)
    {
      if(requestInfo.request.chat_session_id)
      {
        state.addedRecord=requestInfo.request.chat_session_id;
      }
      state.recordSessions.splice(requestInfo.position,0,requestInfo.request)
    },
    resetAddedRecord(state)
    {
      state.addedRecord=null;
    },
    updateRecordSessionOffset(state,addition)
    {
      state.recordSessionOffset+=addition;
    },
    setLoggingOut(state,logging)
    {
      state.loggingOut=logging;
    },
    setSelectedRequestType(state,type){
      state.selectedRequestType=type;
    },
    setLargeModel(state,large){
      state.largeModel=large;
    }
  },
  actions: {
    checkResetSession({commit,dispatch}){
      if(this.state.newSession==true)
        {
          commit('setNewSession',false)
          dispatch('getMostRecentRequest')
        }
    },
    resetState({commit}){
      commit('reset');
    },
    //?This function is called by many others and will return the user to the login page and reset all of the current values
    // How is this different from callLogoutAPI
    async loggedOut({ commit, dispatch }) {

      if(this.state.loginErrorShown==false)
      {
        dispatch('callLogOutAPI');
        alert("You are logged out.\nPlease log in again")//this will pop up the box and have it go back to the main page 
        router.replace({name:'Login'})//push back to the login screen 
        commit('reset');
        commit('setErrorShown',true);
      }
      console.warn("You are logged out please log in again to get data");
    },

   async startNewSession({commit}){
    commit("setNewSession",true); 
    commit('setModelConversation',[])
   },

    async serverLogin({ commit }, loginInfo) {
      return new Promise((resolve,reject) => {
        callLogin(loginInfo).then((res) => {
          if(res.status == 'success')
          {
            commit('setLoggedIn',true);
          }
          else{
            commit('setLoggedIn',false);
          }
          resolve(res)
          // commit;
          // commit("doNothing");
        })
        .catch((err)=>{
          console.error('Error: could not sign into server with the message: ',err)
          reject(err)
        });
      });
    },


    async getEmailResponse({ commit, dispatch }, emailInfo) {
      return new Promise((resolve,reject) => {
        emailInfo.large_model=this.state.largeModel;
        emailInfo.new_session=this.state.newSession;
        emailInfo.chat_topic_id=this.state.chatTopicId;
        POSTRequestJsonResponse(emailInfo,"/email").then((res) => {
          if(res.status == 'success')
          {
            commit('setLoggedIn',true);
            dispatch('checkResetSession');
          }
          else{
            reject(parseErrorReturn('Error: Unable to get an email response',res))
          }
          resolve(res)
          // commit;
          // commit("doNothing");
        })
        .catch((err)=>{
          reject(parseErrorReturn('Error: Unable to get an email response',err))
        });
      });
    },


    async getSocialMediaResponse({ commit, dispatch }, queryInfo) {
      return new Promise((resolve,reject) => {
        queryInfo.large_model=this.state.largeModel;
        queryInfo.new_session=this.state.newSession;
        queryInfo.chat_topic_id=this.state.chatTopicId;
        POSTRequestJsonResponse(queryInfo,"/social_media").then((res) => {
          if(res.status == 'success')
          {
            commit('setLoggedIn',true);
            dispatch('checkResetSession');
          }
          else{
            reject(parseErrorReturn('Error: Unable to get a social media response',res))
          }
          resolve(res)
          // commit;
          // commit("doNothing");
        })
        .catch((err)=>{
          reject(parseErrorReturn('Error: Unable to get a social media response',err))
        });
      });
    },

    async getBlogPostResponse({ commit, dispatch }, queryInfo) {
      return new Promise((resolve,reject) => {
        queryInfo.large_model=this.state.largeModel;
        queryInfo.new_session=this.state.newSession;
        queryInfo.chat_topic_id=this.state.chatTopicId;
        POSTRequestJsonResponse(queryInfo,"/blog_post").then((res) => {
          if(res.status == 'success')
          {
            commit('setLoggedIn',true);
            dispatch('checkResetSession');
          }
          else{
            reject(parseErrorReturn('Error: Unable to get a blog post response',res))
          }
          resolve(res)
          // commit;
          // commit("doNothing");
        })
        .catch((err)=>{
          reject(parseErrorReturn('Error: Unable to get a blog post response',err))
        });
      });
    },

    async getRagWithDocsResponse({ commit, dispatch }, queryInfo) {
      return new Promise((resolve,reject) => {
        let path='/rag_with_docs'
        if(queryInfo.agent==true)
          {
            path='/rag_with_docs1'
          }
          queryInfo={user_query:queryInfo.user_query,
            new_session:false,
            chat_topic_id:"0bcb40ac-8daf-475a-8c25-650ab5d45fd7", //? this is a placeholder and will need to be swapped with an actual value when we have all this in 
            large_model:this.state.largeModel
          }
        POSTRequestJsonResponse(queryInfo,path).then((res) => {
          if(res.status == 'success')
          {
            commit('setLoggedIn',true);
            dispatch('checkResetSession');
          }
          else{
            reject(parseErrorReturn('Error: Unable to get a blog post response',res))
          }
          resolve(res)
          // commit;
          // commit("doNothing");
        })
        .catch((err)=>{
          reject(parseErrorReturn('Error: Unable to get a blog post response',err))
        });
      });
    },


    async getWebChatResponse({ commit, dispatch }, queryInfo) {
      return new Promise((resolve,reject) => {
        let path='/web_search'
        if(queryInfo.agent && queryInfo.agent==true)
          {
            path="/web_search_with_function_calls"
          }
          queryInfo={user_query:queryInfo.user_query,
            new_session:false,
            chat_topic_id:"0bcb40ac-8daf-475a-8c25-650ab5d45fd7", //? this is a placeholder and will need to be swapped with an actual value when we have all this in 
            large_model:this.state.largeModel
          }
        POSTRequestJsonResponse(queryInfo,path).then((res) => {
          if(res.status == 'success')
          {
            commit('setLoggedIn',true);
            dispatch('checkResetSession');
          }
          else{
            reject(parseErrorReturn('Error: Unable to get a blog post response',res))
          }
          resolve(res)
          // commit;
          // commit("doNothing");
        })
        .catch((err)=>{
          reject(parseErrorReturn('Error: Unable to get a blog post response',err))
        });
      });
    },

    async getMostRecentRequest({ commit }) {
      return new Promise((resolve,reject) => {
        let requestInfo={
          fetch:1,
          offset:0,
        }
        GETRequestJsonResponse(requestInfo,"/record_sessions").then((res) => {
          if(res.status == 'success' && res.results && res.results.length==1)
          {
            commit('insertRecordSession',{position:0,request:res.results[0]}) 
            commit('updateRecordSessionOffset',requestInfo.fetch)
          }
          else{
            reject(parseErrorReturn('Error: Unable to get historic request',res))
          }
          resolve(true)
          // commit;
          // commit("doNothing");
        })
        .catch((err)=>{
          reject(parseErrorReturn('Error: Unable to get an email response',err))
        });
      });
    },


    async getRecordSessions({ commit }, requestInfo) {
      return new Promise((resolve,reject) => {
          requestInfo.offset=this.state.recordSessionOffset;
        GETRequestJsonResponse(requestInfo,"/record_sessions").then((res) => {
          if(res.status == 'success' && res.results)
          {

            commit('addRecordSessions',res.results);
            commit('updateRecordSessionOffset',requestInfo.fetch)
          }
          else{
            reject(parseErrorReturn('Error: Unable to get historic requests',res))
          }
          resolve(true)
          // commit;
          // commit("doNothing");
        })
        .catch((err)=>{
          reject(parseErrorReturn('Error: Unable to get an email response',err))
        });
      });
    },


    async responseFeedback({commit},feedbackObj){
      return new Promise((resolve,reject) => {
        commit
        POSTRequestJsonResponse(feedbackObj,'/response_feedback').then((res) => {
          if(res.status != 'success')
          {
            console.error(parseErrorReturn('Error: Unable to get a blog post response',res))
            reject(parseErrorReturn('Error: Unable to get a blog post response',res))
          }
          resolve(res)
        })
        .catch((err)=>{
          reject(parseErrorReturn('Error: Unable to get a blog post response',err))
        });
      });
    },

    updateLargeModel({commit},largeChecked){
      commit('setLargeModel',largeChecked);
    },

    async getSessionRecords({ commit }, chatSessionID) {
      return new Promise((resolve,reject) => {
        commit('resetAddedRecord');
        commit('setNewSession',false); 
        GETRequestJsonResponse({chat_session_id:chatSessionID},"/session_records").then((res) => {
          if(res.status == 'success' && res.results)
          {
            resolve(res.results);
          }
          else{
            reject(parseErrorReturn('Error: Unable to get session records',res))
          }
          resolve(true)
          // commit;
          // commit("doNothing");
        })
        .catch((err)=>{
          reject(parseErrorReturn('Error: Unable to get an email response',err))
        });
      });
    },


    async showLoggedOutMessage({commit},show)
    {
      commit('setShowLoginError',show)
    },

    async logUserOut({commit})
    {
      POSTRequestJsonResponse({},"/logout")
      commit("setLoggedIn",false);
      // todo end the rest of it and clear all of the data or find a way to hard refresh the site
      commit('reset');
      commit('setLoggingOut',true)
      this.state.routerHistory[this.state.routerHistory.length-1]='/';
      router.replace({path:'/'})
    },

  },
});

function parseErrorReturn(start,err){
  let strError=start
  let found=false;
  if(err.message)
  {
    strError+=" With the message: "+err.message;
    found=true;
  }
  if(err.results && err.results.errorMessage)
  {
    strError+=" and the specifics: "+err.results.errorMessage;
    found=true;
  }
  if(!found)
  {
    strError+=" With the message: "+err;
  }
  console.error('parsing an error with the message',strError)
  return strError;
}

export  { store };
