<template>
    <div class="side-bar">
      <div class="icon-section">
      <font-awesome-icon class="new-icon" @click="newRequestSession" v-tooltip="'New\nSession'" :icon="['fas','plus-square']" /> 
      </div>
      <div class="scroll-section"  @scroll="checkScroll" ref="infiniteList">
      <div  class="record-scroll" v-for="(request,index) in recordSessions" :key="index">
        <historic-request-entry 
        :requestData="request"
        :selected="historicSelected"
        @selectRequest="recordSessionSelected"
        />
      </div>
      <div class="loading-spinner" v-if="loading"><Spinner_2/></div>
      <div class="loading-spinner" v-if="!loading && recordSessions.length==0">
        <p>No Records</p>
      </div>
      </div>
  </div>
  </template>
  
  <script>
  import { mapState, mapMutations } from "vuex";
import HistoricRequestEntry from './HistoricRequestEntry.vue';
  import Spinner_2 from "./Spinner_2.vue";
  export default {
    name: "HistoricalRequests",
    components: {
      Spinner_2,
      HistoricRequestEntry,
    },
    props:{
  
    },
    created(){
      if(this.recordSessions.length==0)
      {
        this.getRecords(15);
      }
    },
    data() {
      return {
        loading:false,
        canLoad:true,
      };
    },
    watch: {
      addedRecord(){
        if(this.addedRecord!=null && this.addedRecord!=undefined)
        {
          let found = this.recordSessions.find((record)=> record.chat_session_id==this.addedRecord);
          if(found)
          {
            this.historicSelected=found;
          }
        }
      }
    },
    computed: {
      ...mapState({
        requestType:(state)=>state.requestType,
        recordSessions:(state)=>state.recordSessions,
        recordSessionOffset:(state)=>state.recordSessionOffset,
        addedRecord:(state)=>state.addedRecord,
        historicSelected:(state)=>state.historicSelected,
      }),
      historicSelected: {
      get() {
        return this.$store.state.historicSelected;
      },
      set(value) {
        this.setHistoricSelected(value);
      }
    }
    },
    methods: {
        ...mapMutations({
          setHistoricSelected: 'setHistoricSelected'
        }),
      newRequestSession(){
        this.$store.dispatch("startNewSession")
      },
      recordSessionSelected(requestSelected){
        this.historicSelected=requestSelected;
        this.$emit('historicRecordSelected',requestSelected);
      },
      getRecords(fetch=5){
        if(this.recordSessionOffset>this.recordSessions.length)
        {
          console.warn('We have hit the max value and do not want to load anymore')
          this.loading=false;
          return;
        }
        this.loading=true;
        this.$store.dispatch('getRecordSessions',{fetch:fetch}).then((rec)=>{
          if(rec)
          {
            this.loading=false;
            //todo look here to to see if it fills the max height and if not go back and get some more so we can be sure the infinite scroll will work 
            this.checkScroll()
          }
        }).catch((err)=>{
          this.loading=false;
          console.error('Error while trying to get records with the err',err)
          //todo show some kind of error message 
        })
      },
      reset(){
        this.$store.dispatch('resetState');
      },
        returnRecordInfo(){

        },
        checkScroll()
        {
            this.$nextTick(()=>{
           let scrollWindow=this.$refs.infiniteList;
            if(scrollWindow.scrollTop + scrollWindow.clientHeight >= scrollWindow.scrollHeight && !this.loading)
            {
                scrollWindow.scrollTop==0 && scrollWindow.clientHeight==scrollWindow.scrollHeight?this.getRecords(10) :this.getRecords() //? if the screen is not full load with more than ususal records
                //todo add a call to the server to get the records and add a loading pace to the bottom
            }
            })
        },
    },
  };
  </script>
  
  <style lang="scss" scoped>
  @import "../assets/styles/main.scss";
  @import "./styles/HistoricalRequests.scss"
  </style>
  