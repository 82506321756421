// import "@babel/polyfill";
// import * as Vue from "vue";
import {createWebHistory,createRouter} from "vue-router";
/** Old Routes **/

import ModelRequest from "../pages/ModelRequest";
import SignIn from "../pages/SignIn";


const routes=[
    {
        path: "/", //?Uncomment when login page is done
        //path: "/Login",
        name: "Sign In",
        component: SignIn,
      },
      {
        path: "/model-request",
        name: "Model Request",
        component: ModelRequest,
      },
      { path: '/:pathMatch(.*)*', redirect: '/login' } // Redirect any unknown routes to the login page
]


// Vue.use(Router);

const router = createRouter({
    history:createWebHistory(process.env.BASE_URL),
    routes
});

export default router;
