<template>
    <div class="record-backdrop" v-bind:class="{'request-selected':isSelected}" @click="selectRequest">
        <div class="record-entry">
            <div class="information-section">
            <span class="record-date">
               <!-- <font-awesome-icon class="request-icon" :icon="requestIcon" /> -->
               {{ formatDateTime(requestData.start_time)}}</span>
            <span v-if="includeTitle" class="hidden-overflow record-text-section" ><b><br>Project: </b>{{requestData.user_input['project']}}</span>
            <div class="text-section" >
                <span ref="textContent" id="text-area" v-bind:class="{'hidden-overflow record-text-section':expanded}" v-if="requestData.user_input['user_query']" class="record-text-section ">
                  <b v-if="includeTitle">Information: </b>
                  {{requestData.user_input['user_query']}}
                </span>
            <div v-if="overflowing==true" v-tooltip.top="expanded?'Expand':'Collapse'" @click="expandText" class="expand-button" ><font-awesome-icon class="expand-icon" :icon="expandIcon" /></div>
            </div>
            </div>
        </div>
        <div class="loading-spinner" v-if="loading"><Spinner_2/></div>
        </div>
  </template>
  
  <script>
  import { mapState } from "vuex";
  import Spinner_2 from "./Spinner_2.vue";
  export default {
    name: "HistoricRequestEntry",
    components: {
      Spinner_2,
    },
    props:{
        requestData:Object,
        selected:Object
    },

    data() {
      return {
        loading:false,
        expanded:true,
        overflowing:null,
      };
    },
    watch: {
      requestData()
      {
        setTimeout(()=>{
          this.checkOverflowing()
        },50)
      }
    },

      mounted() {
      this.checkOverflowing();
  },

    computed: {
      ...mapState({
        requestTypes:(state)=>state.requestTypes,
      }),
      includeTitle(){
        if(this.requestData.user_tool_id==0 && this.requestData.user_input['project'])
        {
          return true;
        }
        return false;
      },
      isSelected(){
        if(this.selected && this.selected.chat_session_id && this.selected.chat_session_id==this.requestData.chat_session_id)
        {
            return true;
        }
        return false;
      },
      expandIcon(){
        return this.expanded? ['fas','angle-down'] : ['fas','angle-up']
      },
      requestText(){
        if(this.requestData.user_input)
        {
            return this.requestData.user_input;
        }
        else{
            return 'Error: Could not find user input';
        }
      },
      requestIcon(){
        if(!this.requestData.user_tool_id && this.requestData.user_tool_id!=0)
        {
            return; //todo return some kind of error
        }
        // let found = this.requestTypes.find((req)=>req.value==this.requestData.user_tool_id)
            // switch (this.requestData.user_tool_id)
            // {
            //     case this.requestType['Email']:
            //     return ['fas','envelope'];

            //     case this.requestType['SocialMedia']:
            //     return ['fas','hashtag'];


            //     case this.requestType['BlogPost']:
            //     return ['fas','blog'];

                // default:
            return this.requestTypes[this.requestData.user_tool_id].icon;
            // }
      },

    },
    methods: {
      checkOverflowing()
      {
        let contentElement = this.$refs.textContent;
      if (contentElement) {
        this.overflowing = contentElement.scrollWidth > contentElement.clientWidth;
      }
      else{
        setTimeout(()=>{
          this.checkOverflowing()
        },50)
      }
      },

    selectRequest(){
        this.$emit('selectRequest',this.requestData);
    },
    formatDateTime(dateTimeString) {
    const dateTime = new Date(dateTimeString);
    const options = { 
    //   weekday: 'long', 
      year: 'numeric', 
      month: 'numeric', 
      day: 'numeric', 
      hour: '2-digit', 
      minute: '2-digit', 
    //   second: '2-digit' 
    };
    return dateTime.toLocaleDateString(undefined, options);
  },
    expandText(event) {
        if(this.overflowing)
        {
            event.stopPropagation(); // Stop the propigation of the click to the upper section
            this.expanded=!this.expanded;
        }
    }
    },
  };
  </script>
  
  <style lang="scss" scoped>
  @import "../assets/styles/main.scss";
  @import "./styles/HistoricRequestEntry.scss"
  </style>
  