<template>
    <div class="modal-backdrop">
      <div class="modal modal--groups-modal">
        <header class="modal-header">
          Sign Out
        </header>
  
        <body class="modal-body">
          <div class="error-body">
          Would you like to Sign Out?
          </div>
        </body>
  
        <footer class="modal-footer">
          <!-- <button type="button" class="btn-apply" @click="apply">
            Apply Selection
          </button> -->
          <button
          type="button"
          class="btn-apply"
          @click="cancel();">
          No
        </button>
          <button
            type="button"
            class="btn-apply"
            @click="confirm();">
            Yes
          </button>
        </footer>
      </div>
    </div>
  </template>
  
  <script>
  // import { mapState } from "vuex";
  
  export default {
    name: "LogoutPromptModal",
    props:[],
    components: {
    },
  
    mounted(){
    },
    data() {
      return {
      };
    },
    computed: {
    },
    methods: {
      confirm() {
        if (this.confirmLogoutCallback) {
        this.confirmLogoutCallback(); // Call the confirmLogoutCallback if it exists
      }
      },
      cancel(){
        if (this.cancelLogoutCallback) {
        this.cancelLogoutCallback(); // Call the confirmLogoutCallback if it exists
      }
      }
    },
  };
  </script>
  
  <style lang="scss" scoped>
  @import "../styles/BasicModal.scss";
  @import "../styles/Buttons.scss";
  @import "../styles/ErrorMessage.scss";
  </style>
  