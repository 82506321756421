<template>
    <div id="ModelRequest">
      <!-- <div id="sideBar"> -->
        <Header v-if="true" />
        <div class="body-section">
        <historical-requests 
        ref="historic"
        @historicRecordSelected="historicRecordChosen"
        />
      <!-- </div> -->
      <div id="mainSection">


    <div id="mainContent" ref="pageContent" @scroll="checkShowDown">
      <!-- <div class="title">
        <font-awesome-icon class="title-icon" v-if="selectedRequestOption.icon"  :icon="selectedRequestOption.icon" />  
        <h1>{{selectedRequestOption.label}}</h1> 
      </div> -->
      <div class="conversation" id="conversation" ref="conversation">
        <div v-for="(conversationPiece,index) in modelConversation" :key="index">
          <ConversationEntry 
          :conversationObject="conversationPiece"
          />
        </div>
        <div v-if="loading" class="loading-spinner"><Spinner_2  /></div>
        <div ref='after'></div>

      <!-- <div v-for="(conversationPiece, index) in modelConversation" :key="index"> 
        <UserRequest 
        v-if="!conversationPiece.modelResponse"
        :requestData="conversationPiece"
        />
        <ModelResponse 
        v-if="conversationPiece.modelResponse"
        :responseData="conversationPiece"
        />
      </div>
      <Spinner_2 v-if="loading" class="login-spinner" /> -->
      <!-- <Spinner_2 v-if="true" class="login-spinner" /> -->
      </div>
      <div class="enter-info" v-if=" modelConversation && modelConversation.length==0 && !loading">
        <!-- <div class="enter-info" v-if="modelConversation.length==0 && !loading"> -->
        <h3>Submit a query to hear back from the model</h3>
      </div>

    <!-- <h1> Email </h1>
    <Spinner_2 v-if="loading" class="login-spinner" />
    <input type="text" v-model="project" placeholder="Project" required>
    <input type="textbox" v-model="information" placeholder="Information" required>
    <div style="display:flex;justify-content:center;align-items:center;flex-flow:column;">
      <input class="response-box" type="textbox" readonly  v-model="responseText" />
    <br><button @click="testEmail" disabled>Test Email</button><br>
    <button @click="logOut">Log Out</button>
    </div> -->
    <div class="down-arrow" v-if="showDownArrow" @click="goToListBottom" >
      <font-awesome-icon :icon="['fas', 'chevron-down']" />
    </div>
  </div>


  <div id="inputArea" > 
        <custom-dropdown 
    @requestTypeSelected="requestOptionSelected"
    :options='requestTypes'
    :selected="selectedRequestOption"
    />
    <textarea v-if="selectedRequestOption.value==0" v-on:keydown.enter="checkKeyPressSubmit" ref="projectQuery" spellcheck="true" class="query-input project-input" v-model="project" placeholder="Project" required></textarea>
    <div class="text-submit" >
    <textarea v-on:keydown.enter="checkKeyPressSubmit" ref="textAreaQuery" spellcheck="true"  class="query-input" v-model="information" placeholder="Information" required></textarea>
    <div class="submit-icon" @click="sendRequest" v-tooltip.top="'Send'" v-bind:class="{'disabled-icon':sendIconDisabled}" >
      <font-awesome-icon class="send-icon"  :icon="['fas','location-arrow']" /> 
    </div>
    </div>
    <div class="checkbox-area">
      <div v-if="selectedRequestOption.value==3 || selectedRequestOption.value==4" class="select-box">
      <span  @click="checked=!checked" ><span v-if="selectedRequestOption.value==3">Framework:</span><span v-if="selectedRequestOption.value==4">Tools:</span></span>
    <input  type="checkbox" v-model="checked" >
    </div>


    <!-- ?? UNCOMMENT THIS PART IF YOU WANT THE LARGE MODEL TO BE SELECTABLE -->

    <!-- <span v-if="selectedRequestOption.value==3" style="padding-top:5px;"></span>
    <div v-if="true" class="select-box">
      <span @click="largeChecked=!largeChecked" >Large Model:</span>
    <input  type="checkbox" v-model="largeChecked" >
    </div> -->


    </div>
  </div>
  </div>

  </div>
    </div> 
  </template>
  <script>
  import {mapState, mapMutations} from 'vuex';
import Spinner_2 from "../components/Spinner_2.vue";
import HistoricalRequests from "../components/HistoricalRequests.vue";
import Header from '../components/Header.vue'; 
import CustomDropdown from '../components/CustomDropdown.vue';      
// import UserRequest from '../components/UserRequest.vue';
// import ModelResponse from '../components/ModelResponse.vue';  
import ConversationEntry from '../components/ConversationEntry.vue';
  export default { 
    name: "ModelRequest",
    components: {
      Spinner_2,
      HistoricalRequests,
      Header, 
      CustomDropdown,
      // UserRequest,
      // ModelResponse,
      ConversationEntry
    },
    data() {
      return {
        project:"",
        information:"",
        loading:false,
        responseText:"",
        maxRows:5,
        checked:true,
        largeChecked:false,
        selectedRequestOption:{},
        showDownArrow:false,
      };
    },
    mounted(){
        this.checkResize();
        this.selectedRequestOption=this.requestTypes[this.requestTypes.length-1];
        // this.modelConversation = Array.from({ length: this.requestTypes.length }, () => []);
        // this.modelConversation = Array.from({ length: this.requestTypes.length }, () => []);
    },
    computed:{
      ...mapState({
        loggedIn: (state) =>state.loggedIn, 
        requestTypes:(state) =>state.requestTypes,
        modelConversation:(state)=>state.modelConversation,
      }),
      
      modelConversation:{
        get(){
          return this.$store.state.modelConversation;
        },
        set(value){
          this.setModelConversation(value);
        }
      },
      lastMessage(){
        return this.modelConversation[this.modelConversation.length-1]
      },
      sendIconDisabled(){
        if(this.loading==true)
        {
          return true;
        }
        if(this.selectedRequestOption.value==0 && this.project=='')
        {
          return true;
        }
        if(this.information=='')
        {
          return true;
        }
        return false;
      }
      },
    // Please reference store/readme.md file for all API calls
    watch:{
      information()
      {
        this.checkResize();
      },
      project(){
        this.checkResize(true);
      },
      largeChecked(){
        this.$store.dispatch('updateLargeModel',this.largeChecked)
      }
    },
    methods: {
      ...mapMutations({
        setModelConversation:'setModelConversation'
      }),
      checkShowDown(){
        this.$nextTick(() => {
        if(this.$refs && this.$refs.pageContent && !this.loading)
        {
          return this.showDownArrow=this.$refs.pageContent.scrollTop + this.$refs.pageContent.clientHeight < this.$refs.pageContent.scrollHeight;
        }
        else{
          this.showDownArrow=false
        }
      });
      },
      checkKeyPressSubmit(e)
      {
        if(e.ctrlKey || e.shiftKey || e.altKey || e.metaKey)//||this.information.includes('\n'))//? include this if you want to allow the user to press enter after one of the other options
        {
          if(e.ctrlKey||e.altKey)
          {
            this.information+="\n";
          }
        }
        else{
          e.preventDefault();
          this.sendRequest();
        }
      },
      sendRequest(){
        if(this.loading==true)
        {
          return;
        }
        // if(this.$refs.historic && this.$refs.historic.historicSelected)
        // {
        //   this.$refs.historic.historicSelected={};
        // }
        this.loading=true;
        this.goToListBottom();
        let userQueryData={
          modelResponse:false,
          user_query:this.information,
          user_tool_id: this.selectedRequestOption.value,
          query_data:{
            project:this.project,
          }
        }

        this.modelConversation.push(userQueryData);
        switch(this.selectedRequestOption.value){
          case 0:
            this.getEmailResponse(this.information,this.project);
          break;

          default:
            this.getGeneralResponse(this.information,this.selectedRequestOption.value);
          break;
        }
        this.resetInput();
      },
      resetInput()
      {
        this.information="";
        this.project="";
      },
      checkResize(project=false){
      const textarea = project?this.$refs.projectQuery: this.$refs.textAreaQuery;

      textarea.style.height = `auto`; // Set the height to the scrollHeight
      if(textarea.scrollHeight>textarea.clientHeight)
      {
        textarea.style.height = `${Math.min(textarea.scrollHeight-10,85)}px`; // Set the height to the scrollHeight
      }
      },
      goToListBottom(){
        this.$nextTick(()=>{
            this.$refs.after?.scrollIntoView({behaviour:'smooth',block: "end", inline: "nearest"});
          })
      },
      requestOptionSelected(option)
      {
        this.selectedRequestOption=option;
      },
      historicRecordChosen(historicRecord)
      {
        this.selectedRequestOption=this.requestTypes.find(obj=>obj.value==historicRecord.user_tool_id)
        this.modelConversation=[];
        this.loading=true;
        this.goToListBottom();
          this.$store.dispatch('getSessionRecords',historicRecord.chat_session_id).then((records)=>{
            for(var record of records){
              this.modelConversation.push(record)
            }
            this.loading=false;
          //   this.$nextTick(() => {
          //     const cont=this.$refs.conversation;
          //     cont.scrollTop = cont.scrollHeight;
          // });
            this.goToListBottom();
            this.checkShowDown();
        }).catch((err)=>{
          this.loading=false;
          console.error('We got an error while getting the specifics',err)

          this.lastMessage.success=false
          this.lastMessage.response=err
        })
        // this.$store.dispatch('getSpecificRecord',historicRecord.chat).then((records)=>{
        //   this.loading=false;

        // }).catch((err)=>{
        //   this.loading=false;
        //   console.error('We got an error while getting the specifics',err)

        // })
      },
      getEmailResponse(information,project){
        this.goToListBottom();
        this.$store.dispatch('getEmailResponse',{
          "user_query":information,
          "project":project
        }).then((res)=>{
          if(res.status && res.status=='success' && res.results && res.results.response)
          {
            this.lastMessage.success=true;
            this.lastMessage.response = res.results.response;
            this.lastMessage.record_id=res.results.model_request_id;
            // this.modelConversation.push({
            //   modelResponse:true,
            //   queryType:0,
            //   success:true,
            //   response:res.results.response,
            //   record_id:res.results.model_request_id,
            //   queryData:{
            //     user_query:information,
            //     project:project,
            //   }
            // })
            // this.responseText=res.results.response;
          }
          else if(res.status && res.status=='failed' && res.message)
          {
            this.lastMessage.success=false;
            this.lastMessage.response=res.message
          }
          else{
            this.responseText="Error: could not get email from server";
            this.lastMessage.success=false;
            this.lastMessage.response="Error: could not get email from server"
          }
          this.loading=false;
          this.checkShowDown();
        }).catch((err)=>{
          this.loading=false;
            this.lastMessage.success=false;
            this.lastMessage.response=err;
            this.checkShowDown();
        })
      },


      getGeneralResponse(information,queryType){

        let functionCall='';
        let fullResponse=false;

        switch(queryType){
          case(1): 
            functionCall='getSocialMediaResponse'
            break;
          case(2):
          functionCall='getBlogPostResponse';
          break;

          case(3):
          functionCall="getRagWithDocsResponse";
          break;

          case(4):
          functionCall="getWebChatResponse";
          fullResponse=true;
          break;
        }

        let user_data={
          "user_query":information
        }
        if(this.selectedRequestOption.value==3||this.selectedRequestOption.value==4)
        {
          user_data.agent=this.checked
        }
        this.$store.dispatch(functionCall,user_data).then((res)=>{
          if(res.status && res.status=='success' && res.results && res.results.response)
          {
            fullResponse
            // let respObj={
            //   modelResponse:true,
            //   queryType:queryType,
            //   success:true,
            //   response:res.results.response,
            //   record_id:res.results.model_request_id,
            //    queryData:fullResponse==true && res.results.sources?{
            //     user_query:information,
            //     sources:res.results.sources
            //   }:{user_query:information}
            // }
            this.lastMessage.success=true;
            this.lastMessage.response=res.results.response;
            this.lastMessage.record_id=res.results.model_request_id;
            this.lastMessage.sources= res.results.sources? res.results.sources:null;


          }
          else if(res.status && res.status=='failed' && res.message)
          {
            this.lastMessage.success=false;
            this.lastMessage.response=res.message;
          }
          else{
            this.responseText="Error: could response from server";
            this.lastMessage.success=false;
            this.lastMessage.response="Error: could not response from server"
          }
          this.loading=false;
          this.checkShowDown();
        }).catch((err)=>{
          this.loading=false;
          this.responseText="Error: not able to get response "+err
            this.lastMessage.success=false;
            this.lastMessage.response=err;
            this.checkShowDown();
        })
      },

      logOut()
      {
        // this.$store.dispatch('showLoggedOutMessage',true)

        this.$store.dispatch("logUserOut");
      }
//       loginGEMS() {
//         this.$store.dispatch("loginGEMS", {
//           email: "sunny@sisfoundation.ca",
//           password: "Cuc25169sis",
//         });
//       },
    },
  };
  </script>
  
  <style scoped>
  @import "./styles/ModelRequest.scss";
  @import "../assets/styles/main.scss";
  </style>
  