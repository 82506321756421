import {store} from "../store/Store";

//
// For production, uncomment
//
// let testServer = 'https://sisfoundation.swiftlearning.com';
//
//
// For testing, comment out for production
//
// let testServer = "https://localhost:9000/api";
let testServer = "https://api.swiftaitechnologies.com/api";

//
//
//


let origin = "";


const APICalls = {
  
};


const logUserOut=()=>{
  store.state.authToken="";
  store.dispatch('showLoggedOutMessage',true)
};



/**
 * This is a request to the old server
 * @param {Object} body
 * @returns a JSON object
 */
const POSTRequestJsonResponse = (body, urlEndpoint) => {
  return new Promise((resolve, reject) => {
    const myHeaders = new Headers();
    myHeaders.set(
      "Content-Type",
      "application/x-www-form-urlencoded;charset=UTF-8"
    );
    myHeaders.set("accept", "application/json, text/plain, text/html");
    myHeaders.append("Authorization",`Bearer ${store.state.authToken}`);
    const requestOptions = {
      method: "POST",
      mode: "cors",
      cache: "reload",
      // credentials: "include",
      headers: myHeaders,
      body: JSON.stringify(body),
      redirect: "follow",
      referrer: origin,
      referrerPolicy: "no-referrer-when-downgrade",
      credentials: "same-origin",
      origin: origin,
    };
    return fetch(testServer + urlEndpoint, requestOptions)
      .then((response) => {
          if(response.status==401)
          {
            if(urlEndpoint=='/logout')//? do not show the errors when logging out 
              {
                return;
              }
            logUserOut();
            reject('Used logged out');
            return;
          }
        response.json().then((object) => {
          if(object.status)
          {
            if(object.status=='success')
            {
              resolve(object);
            }
            else{
              reject(object);
            }
          }
            resolve(object);
            //return object
        })
      }).catch((err)=>{
        console.error('We have an error with the response and the message',err)
      }).catch((err1)=>{
        console.error('In the second error catch',err1)
      }) 
  });
};



/**
 * This is a request to the old server
 * @param {Object} body
 * @returns a JSON object
 */
const GETRequestJsonResponse = (parameters, urlEndpoint) => {
  return new Promise((resolve, reject) => {
    const myHeaders = new Headers();
    myHeaders.set(
      "Content-Type",
      "application/x-www-form-urlencoded;charset=UTF-8"
    );
    myHeaders.set("accept", "application/json, text/plain, text/html");
    myHeaders.append("Authorization",`Bearer ${store.state.authToken}`);
    const requestOptions = {
      method: "GET",
      mode: "cors",
      cache: "reload",
      // credentials: "include",
      headers: myHeaders,
      redirect: "follow",
      referrer: origin,
      referrerPolicy: "no-referrer-when-downgrade",
      credentials: "same-origin",
      origin: origin,
    };
    const url = new URL(testServer + urlEndpoint);

    // Appending query parameters to the URL
    for (const param in parameters) {
      url.searchParams.append(param, parameters[param]);
    }
    return fetch(url, requestOptions)
      .then((response) => {
          if(response.status==401)
          {
            logUserOut();
            reject('Used logged out');
            return;
          }
        response.json().then((object) => {
          if(object.status)
          {
            if(object.status=='success')
            {
              resolve(object);
            }
            else{
              reject(object);
            }
          }
            resolve(object);
            //return object
        })
      }).catch((err)=>{
        console.error('We have an error with the response and the message',err)
        reject(err);
        logUserOut();
      }).catch((err1)=>{
        console.error('In the second error catch',err1)
        reject(err1);
        logUserOut();
      }) 
  });
};


// Returns a file, might be specialized
/**
 * This is a request to the old server
 * This might be specialized request
 * @param {Object} body
 * @returns a file
 */
const POSTFileResponse = (body) => {
  return new Promise((resolve, reject) => {
    const myHeaders = new Headers();
    myHeaders.set(
      "Content-Type",
      "application/x-www-form-urlencoded;charset=UTF-8"
    );
    myHeaders.set("accept", "application/json, text/plain, text/html");
    const requestOptions = {
      method: "POST",
      mode: "cors",
      cache: "reload",
      // credentials: "include",
      headers: myHeaders,
      body: JSON.stringify(body),
      redirect: "follow",
      referrer: origin,
      referrerPolicy: "no-referrer-when-downgrade",
      credentials: "include",
      origin: origin,
    };
    return fetch(testServer, requestOptions)
      .then((response) => {
        response.text().then((text) => {
          if (text[0] == "{") {
            reject("Logged Out");
          } else {
            let filename = text.split(";");
            filename = filename[1].split("\n");
            fetch(testServer + "/" + filename[0]).then((response) => {
              response.text().then((text) => {
                resolve(text);
              });
            });
          }
        });
      }) // console.error(response.status) === 200
      .then((result) => {
        result;
      })
      .catch((error) => {
        console.error("POSTFileResponse error", error);
        reject("Error with the call");
      });
  });
};



/**
 * gemsCallLogin takes login info and makes a request to the API for an auth token
 * https://swiftapi.swiftlearning.com/api/v1/api_sessions
 * @param {Object} loginInfo contains a String username and String password
 * @returns Promise
 */
const callLogin = (loginInfo) => {
  // const sessionsGEMS = "https://swiftapi.swiftlearning.com/api/v1/api_sessions";
  return new Promise((resolve, reject) => {
    const myHeaders = new Headers();
    myHeaders.set(
      "Content-Type",
      "application/x-www-form-urlencoded;charset=UTF-8"
    );
    myHeaders.set("accept", "application/json");

    process.env.NODE_TLS_REJECT_UNAUTHORIZED = '0';
    const requestOptions = {
      method: "POST",
      mode: "cors",
      cache: "reload",
      headers: myHeaders,
      body: JSON.stringify(loginInfo),
      redirect: "follow",
      referrer: origin,
      referrerPolicy: "no-referrer-when-downgrade",
      credentials: "same-origin",
      origin: origin,
      // agent: new https.Agent({ rejectUnauthorized: false }),
    };
    return (
      fetch(testServer + "/login", requestOptions)
        .then((response) => {
          response.json().then((object) => {
            if(object.status=='success' && object.results && object.results.access_token)
            {
              store.state.authToken=object.results.access_token
              resolve(object);
            }
            else{
              if(object.message)
              {
                reject(object.message);
              }
              else{
                reject('Error: cannot get access token');
              }
            }

          });
        })
        // .then((result) => {
        //   result;
        // })
        .catch((error) => {
          console.error("callLogin error", error);
          reject("Login Error");
        })
    );
  });
};





export {
  APICalls,
  POSTFileResponse,
  POSTRequestJsonResponse,
  GETRequestJsonResponse,
  callLogin,
};
