<template>
  <div id="LoginPage" class="loginDiv">
    <br />
    <img src="../assets/images/energy_ai_logo.png" alt="SWIFT Energy AI" />
    <br />
    <!-- <h4 v-show="!secondaryError">Sign In</h4> -->
    <!-- <Translator /> -->
    <!-- <ChangeLanguage></ChangeLanguage> -->
    <div class="main-section" v-show="!loginIssue && !secondaryError">

      <Spinner_2 v-if="loading" class="login-spinner" />

      <!-- <p>Please enter your email and password</p> -->
      <p v-if="loginMsg" class="loginError" data-cy="loginErrMsg">
        {{ loginMsgStr }}
      </p>
        <div class="input-area">
          <div class="input-container">
            <input v-bind:class="{'input-error':emailState==false}" type="text" v-model="email" placeholder="Email" required>
            <!-- <button class="clear-button" @click="clearEmail" v-if="email"> <font-awesome-icon class="clear-button" :icon="['fas', 'times']" /></button> -->
        <font-awesome-icon @click="email=''" class="input-button" :icon="['fas', 'times']" v-if="email"/>
        </div>
        <div class="input-container">
        <input  @keyup.enter="login()" @keydown="checkCaps" v-bind:class="{'input-error':passwordState==false}" :type="passwordType" v-model="password" placeholder="Password" required>
        <font-awesome-icon @click="passwordType='text'" class="input-button" :icon="['fas', 'eye']" v-if="password && passwordType=='password'" />
        <font-awesome-icon class="input-button" @click="passwordType='password'" :icon="['fas', 'eye-slash']" v-if="password && passwordType=='text'" />
        </div>
        </div>
        <div v-if="capsWarning" class="loginWarning" data-cy="caps-warning">
          <p>WARNING: Caps lock on. <br />Your password may be incorrect</p>
        </div>

        <div class="btn-login">
            <button v-bind:class="{'inactive-button':!canSignIn}" class="btn" @click="login()">Login</button>
        </div>
        <!--<div class="other-login">
          <button class="btn" @click="testLogin()">Test Google Login</button>
          <GoogleLogin :callback="testCallback" />
        </div> -->
    </div>

    <div v-if="loginIssue" id="loginresults" data-cy="login-issue">
      <p>
        There was an issue with the login. <br />This is possibly because you
        have another active session <br />Would you like to Force Login?
        <br />This will end your other sessions
      </p>
    </div>

    <div v-if="secondaryError" id="loginresults" data-cy="login-issue">
      <p class="loginError">
        {{loginMsgStr}} <br> Please try again or contact us if this continues.
      </p>
      >
    </div>

  </div>
</template>

<script>
import axios from "axios";
import Spinner_2 from "../components/Spinner_2.vue";
// const url = "https://service.mtcaptcha.com/mtcv1/api/checktoken";
import { googleSdkLoaded, decodeCredential } from "vue3-google-login";
// import {  googleOneTap } from "vue3-google-login";
//const captchaKey = process.env.VUE_APP_CAPTCHAKEY;
import global from "../util/GlobalFunctions.js";

export default {
  name: "SignIn",
  mixins: [global],
   components: {
    Spinner_2,
  },
  data() {
    return {
      email: "",
      password: "",  
      passwordType: "password", 
      capsWarning: false,
      hideShow: "Show",
      loginIssue: false,
      loginMsg: false,
      loginMsgStr: "",
      verifiedtoken: "",
      isProd: false,
      auth: "",
      loginError:"",
      loading:false,
      secondaryError:false,
    };
  }, 
  computed: {
    emailState() {
      if (this.email === "") {
        return null;
      } else if (!this.emailIsValid(this.email)) {
        return false;
      } else {
        return true;
      }
    },
    passwordState() {
      if (this.password === "") {
        return null;
      } 
      else {
        return this.password.length>=6;
      }
    },
    canSignIn(){
      if(this.emailState==true && this.passwordState==true)
      {
        return true;
      }
      else{
        return false;  
      } 
    } 
  },
  // created: function () {
  //   if(process.env.NODE_ENV === 'production')
  //   {
  //     this.renderCaptchaOnload();
  //     this.isProd=true;
  //   }
  // },
  // created: function(){
  //   console.log('created')
  // },
  mounted: function(){
    // googleOneTap().then((response)=>{
    //   this.testCallback(response);
    // }).catch((error)=>{
    //   console.error('There was an error with the google one tap with the message',error)
    // })
  },
  methods: {
    // renderCaptchaOnload: function () {
    //   window.mtcaptchaConfig.renderQueue.push("login-captcha");
    // },
    checkCaps: function (event) {
      //This function will check the caps lock for the password feild
      if (typeof event.getModifierState=='function' && event.getModifierState("CapsLock")) {
        this.capsWarning = true;
      } else {
        this.capsWarning = false;
      }
    },
    // reloadCaptcha()
    // {
    //  if(process.env.NODE_ENV === 'production')
    //  {
    //   window.mtcaptcha.resetUI();
    //  }
    // },

    testLogin(){
      googleSdkLoaded(google=>{
        google.accounts.oauth2.initCodeClient({
          client_id:"1083079375389-oarkhggg43ch4cags3u8ce6mpr0pdmo4.apps.googleusercontent.com", 
          scope:"email profile openid",
          callback:response=>{
            if(response.code){
              this.getUserInfo(response.code)
            }
          }
        })
        .requestCode()
      })
    },
    async getUserInfo(code) {
      try {
        const response = await axios.post(
          "https://oauth2.googleapis.com/token",
          {
            code,
            client_id:
              "1083079375389-oarkhggg43ch4cags3u8ce6mpr0pdmo4.apps.googleusercontent.com",
            client_secret: "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",
            redirect_uri: "postmessage",
            grant_type: "authorization_code"
          }
        );

        const accessToken = response.data.access_token;

        // Fetch user details using the access token
        const userResponse = await axios.get(
          "https://www.googleapis.com/oauth2/v3/userinfo",
          {
            headers: {
              Authorization: `Bearer ${accessToken}`
            }
          }
        );
        if (userResponse && userResponse.data) {
          // Set the userDetails data property to the userResponse object
          this.userDetails = userResponse.data;
        } else {
          // Handle the case where userResponse or userResponse.data is undefined
          console.error("Failed to fetch user details.");
        }
      } catch (error) {
        console.error("Token exchange failed:", error.response.data);
      }
    },
    testCallback(resp){
      const userData=decodeCredential(resp.credential);
      console.info('the user data found from the google login is',userData)
    },
    login() {
      this.loading=true; 
      this.loginMsg = false;
      this.loginMsgStr = "";
      this.secondaryError=false;
    let loginDetails = {
        email: this.email,
        password: this.password,
      };
      this.$store.dispatch("serverLogin",loginDetails).then((result)=>{
        this.loading=false;
        if(result.status=='success')
        {
          this.$router.push({ name: "Model Request" }); //go to the Course reports page we can change this later when we have more pages done
          return;
        }
        else if(result.message){
          this.loginMsgStr="Login Error: "+result.message;
        }
        else{
          this.loginMsgStr="Login Error: Please try to sign in again.";
        }
        this.loginMsg=true;
      }).catch((err)=>{
        this.loading=false;
        if(err!="")
        {
          this.loginMsgStr=err;
        }
        else{
          this.loginMsgStr="Login Error: Please try to sign in again.";
        }
        this.loginMsg=true;
      });
    },

  },
};
</script>

<style scoped>
@import "./styles/SignIn.scss";
</style>
