let handleOutsideClick;
export const closableDirective = 
{
  mounted(el,binding,vnode){
    handleOutsideClick = (e) => {
      e.stopPropagation()
      //? Get the handler method name and the exclude array from the object used in v-closable
      const { handler, exclude } = binding.value
      //? This variable indicates if the clicked element is excluded
      let clickedOnExcludedEl = false
      exclude.forEach(refName => {
        if (!clickedOnExcludedEl) {
          const excludedEl = vnode.ctx.refs[refName]
          //? See if this excluded element is the same element the user just clicked on
          clickedOnExcludedEl = excludedEl.contains(e.target)
        }
      })
      // We check to see if the clicked element is not
      // the dialog element and not excluded
      if (!el.contains(e.target) && !clickedOnExcludedEl) {
        // If the clicked element is outside the dialog
        // and not the button, then call the outside-click handler
        // from the same component this directive is used in
        //? it was using vnode.context but this seems to work
        vnode.ctx.ctx[handler]()
      }
    };
    document.addEventListener('click', handleOutsideClick)
    document.addEventListener('touchstart', handleOutsideClick)
  },
  unmounted(){
    document.removeEventListener('click',handleOutsideClick);
    document.removeEventListener('touchstart',handleOutsideClick);
  }
};