<template>
    <div id="conversationEntry">  
        <UserRequest 
        :requestData="getUserData()"
        :requestType="selectedRequestType"
        />           
        <ModelResponse v-if="conversationObject.response"
        :responseData="getResponseData()"
        :requestType="selectedRequestType"
        />      
        </div>
  </template>
  
  <script>
  import { mapState } from "vuex";
  // import Spinner_2 from "./Spinner_2.vue";
  import UserRequest from "./UserRequest.vue";
  import ModelResponse from "./ModelResponse.vue";
  export default {
    name: "ConversationEntry",
    components: {
      // Spinner_2,
      UserRequest,
      ModelResponse
    },
    props:{
        conversationObject:Object
    },

    data() {
      return {
        loading:false,
      };
    },
    watch: {
      
    },


    computed: {
      ...mapState({
        requestTypes:(state)=>state.requestTypes,
      }),
      selectedRequestType()
      {
        return this.requestTypes[this.conversationObject.user_tool_id]
      },
      isSelected(){
        if(this.selected && this.selected.record_id && this.selected.record_id==this.requestData.record_id)
        {
            return true;
        }
        return false;
      },
    },
    methods: {
      getUserData(){
        return {
          queryType: this.conversationObject.user_tool_id,
          userQuery: this.conversationObject.user_query,
          queryData: this.conversationObject.query_data,
          record_id: this.conversationObject.record_id
        }
      },
      getResponseData(){
        return {
          sources: this.conversationObject.sources,
          feedback: this.conversationObject.feedback,
          response: this.conversationObject.response,
          queryType: this.conversationObject.user_tool_id,
          queryData: this.conversationObject.query_data,
          success:true,
          record_id:this.conversationObject.record_id
        }
      }
    },
  };
  </script>
  
  <style lang="scss" scoped>
  @import "../assets/styles/main.scss";
  @import "./styles/ChatEntry.scss"
  </style>
  