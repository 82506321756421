<template>
    <div ref="custom-dropdown" class="custom-dropdown" @click="toggleDropdown">
      <div class="selected-option">
        <div class="selected-name-icon">
        <font-awesome-icon class="option-icon"  :icon="selectedOption.icon" /> 
        <!-- <img :src="selectedOption.icon" class="option-icon" /> Display the selected option's icon -->
        <span>{{ selectedOption.label }}</span> <!-- Display the selected option's label -->
        </div>
        <font-awesome-icon class="option-icon" style="margin-left: 4px;"  :icon="['fas','angle-down']" /> 
      </div>

      <transition name="fade">
        <div ref="closable-popup" v-if="isOpen" v-closable="{exclude:['custom-dropdown'],handler:'closeFromClick'}" class="options">
          <div class="option" v-for="(option, index) in options" :key="index" @click="selectOption(option)" >
            <!-- <img :src="option.icon" class="option-icon" /> Display each option's icon -->
            <div v-if=" !option.hidden">
            <font-awesome-icon class="option-icon"  :icon="option.icon" />
            <span>{{ option.label }}</span> <!-- Display each option's label -->
            </div>
          </div>
        </div>
      </transition>
    </div>
  </template>
  
  <script>
  import {closableDirective} from '../composables/ClickOutside.js';

  export default { 
    name:"CustomDropdown",
    props: {
      selected: Object, // Value of the selected option
      options: Array // Array of options [{label: String, value: String, icon: String}, ...]
    },
    data() {
      return {
        isOpen: false, // Flag to toggle dropdown visibility
        selectedOption: { label: 'Email', value: 0, icon: ['fas','envelope'] }, // Object to store selected option
      };
    },
    computed: {
      selectedOptionIndex() {
        return this.options.findIndex(option => option.value === this.selectedType.value);
      }
    },
    directives:{
      closable:closableDirective
    },
    watch: {
      selected(newVal)
      {
        this.selectedOption=this.options.find(opt=>opt.value==newVal.value)
      },
      value(newValue) {
        // Update selectedOption when the value changes
        this.selectedOption = this.options.find(option => option.value === newValue) || { label: '', value: '', icon: '' };
      }
    },
    methods: {
      closeFromClick(){
        this.isOpen=false;
      },
      toggleDropdown() {
        this.isOpen = !this.isOpen; // Toggle dropdown visibility
      },
      selectOption(option) {
        this.selectedOption = option; // Update selected option
        // this.isOpen = false; // Close dropdown
        // this.toggleDropdown();
        this.$emit('requestTypeSelected', option); // Emit selected value to parent component
      }
    }
  };
  </script>
  
  <style lang="scss" scoped>
  @import "../assets/styles/main.scss";
  @import "./styles/CustomDropdown.scss"
  </style>
  