
  
  const parseGroupedUsers = (groupedUsers) => {
    let start = new Date().getTime();
    start;
    var groupedUser = [];
    let users = groupedUsers.split("\n");
  
    for (var i = 0; i < users.length; i++) {
      let user = {};
      let userArr = users[i].split(";");
      user.loginID = userArr[0];
      user.loginID1 = userArr[5];
      user.firstName = userArr[6];
      user.lastName = userArr[7];
      groupedUser.push(user);
    }
    let end = new Date().getTime();
    end;
    return groupedUser;
  };
  
  
  

  
  
  
  export {
    parseGroupedUsers,
  };
  