<template>
    <div class="header-section">
        <img  src="../assets/images/energy_ai_logo.png" alt="SWIFT Energy AI" />
        <font-awesome-icon class="sign-out-icon" @click="signUserOut" v-tooltip="'Sign Out'" :icon="['fas','sign-out-alt']" /> 
    </div>
  </template>
  
  <script>
  import { mapState } from "vuex";
  export default {
    name: "Header",
    components: {
    }, 
    props:{
  
    },
    created(){
    },
    data() {
      return {
        
      };
    },
    watch: {
      
    },
    computed: {
      ...mapState({
      }),
    },
    methods: {
      signUserOut(){
        this.$store.dispatch('logUserOut');
      },
    },
  };
  </script>
  
  <style lang="scss" scoped>
  @import "../assets/styles/main.scss";
  @import "./styles/Header.scss"
  </style>
  