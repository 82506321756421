<template>
    <div id="modelResponse">  
        <div class="user-icon">
            <!-- <font-awesome-icon :icon="['fas', 'user']" /> -->
            <font-awesome-icon  v-if="requestType && requestType.icon"  :icon="requestType.icon" />  
            <span> SWIFT AI</span>
        </div>
        <div class="information-section">
        <div v-if="responseData.success" >
          <span class="text-area">{{formatResponse(responseData.response)}}</span>
          <div v-if="sourcesExist" class="sources-list">
            <p><b>Sources:</b></p>
            <ul v-for="(source, index) in responseData.sources" :key="index">
              <!-- <li><a :href="source" target="_blank">{{ source }}</a> </li> -->
              <li v-html="formatSource(source)"></li>
            </ul>
          </div>
          <br>
          <div class="options-section">
          <font-awesome-icon class="icon" v-tooltip="copyStatus"  @click="copyOutput" :icon="copyIcon" />
          <font-awesome-icon class="icon" v-tooltip="'Send Email'" @click="createEmailLink" v-if="responseData.queryType==0" :icon="emailIcon" />
          <div class="feedback-section">
          <font-awesome-icon class="icon close-icon" v-tooltip="'Good Response'" @click="clickThumb(true)" :class="getThumbClass(true)" :icon="['fas','thumbs-up']" />
          <font-awesome-icon class="icon close-icon" v-tooltip="'Bad Response'" @click="clickThumb(false)" :class="getThumbClass(false)" :icon="['fas','thumbs-down']" />
          <font-awesome-icon class="icon" style="margin-left:15px;" v-tooltip="'Provide Feedback'" @click="openFeedback()" v-bind:class="{'selected-thumb':responseFeedback!=''}" :icon="['fas','comment-alt']" />
          <div v-if="showFeedbackBox" class="feedback-box">
            <textarea ref="feedbackQuery" v-model="responseFeedback" spellcheck="true" placeholder="Enter your feedback"></textarea>
            <button
            type="button"
            class="btn-apply"
            @click="submitFeedback()">
            Ok
          </button>
        </div>
          </div>
          <!-- <font-awesome-icon class="icon" v-tooltip="copyStatus"  @click="copyOutput" :icon="copyIcon" /> -->
          </div>
        </div>
        <div v-if="!responseData.success">
            <span class="text-area error"><b>Error: </b>{{formatError(formatResponse(responseData.response))}}</span>
        </div>
        </div>
        <div class="loading-spinner" v-if="loading"><Spinner_2/></div>
        </div>
  </template>
  
  <script>
  import { mapState } from "vuex";
  import Spinner_2 from "./Spinner_2.vue";
  export default {
    name: "ModelResponse",
    components: {
      Spinner_2,
    },
    props:{
        responseData:Object,
        requestType:Object
    },
    data() {
      return {
        loading:false,
        copyStatus: "Copy",
        defaultCopyIcon: ['fas','copy'],
        defaultEmailIcon: ['fas','envelope'],
        checkmarkIcon:['fas','check'],
        copyIcon:['fas','copy'],
        emailIcon:['fas','envelope'],
        errorIcon:['fas','times'],
        positive:null,
        responseFeedback:"",
        showFeedbackBox:false,
      };
    },

    mounted(){
      this.checkResize();
      if(this.responseData && this.responseData.feedback)
      {
        if(this.responseData.feedback && this.responseData.feedback.score!=null)
        {
          this.positive=this.responseData.feedback.score>0;
        }
        if(this.responseData.feedback && this.responseData.feedback.feedback!=null)
        {
          this.responseFeedback=this.responseData.feedback.feedback;
        }

      }
    },
    computed: {
      ...mapState({
        requestTypes:(state)=>state.requestTypes,
      }),
      isSelected(){
        if(this.selected && this.selected.record_id && this.selected.record_id==this.requestData.record_id)
        {
            return true;
        }
        return false;
      },
      sourcesExist()
      {
        if(this.responseData && this.responseData.sources)
        {
          return true
        }
        return false
      }
    },
    watch:{
      responseFeedback()
      {
        this.checkResize()
      },
    },
    methods: {
      checkResize(){ //Used to allow the text area to grow as needed 
        if(!this.showFeedbackBox)
        {
          return;
        }
      const textarea = this.$refs.feedbackQuery;

      textarea.style.height = `auto`; // Set the height to the scrollHeight
      if(textarea.scrollHeight>textarea.clientHeight)
      {
        textarea.style.height = `${textarea.scrollHeight}px`; // Set the height to the scrollHeight
      }
      },
      formatSource(source){
        if(source.includes(": ") && source.split(": ").length>1 && (source.includes("http") || source.includes("www")))
        {
          let sourceList=source.split(": ")
          return `<span style="font-size:16px;">${sourceList[0]}:</span> <a  href="${sourceList[1]}" target="_blank" style="font-size:14px;" > ${sourceList[1]} </a>`
        }
        else{
          return `<a href="${source}" target="_blank"> ${source} </a>`
        }
      },

      openFeedback() {
            this.showFeedbackBox = true;
            // this.$nextTick(this.$nextTick( this.checkResize()));
            setTimeout(this.checkResize, 0); 
        },
      submitFeedback(){
        this.showFeedbackBox=false;
        this.sendResponseRating()
      },
      getThumbClass(thumb)
      {
      if(thumb==this.positive)
      {
        return 'selected-thumb'
      }
      return null
      },
      clickThumb(thumbPositive)
      {
        if(thumbPositive==this.positive){
          this.positive=null;
        }
        else{
          this.positive=thumbPositive
        }
        this.sendResponseRating()
      },
      sendResponseRating(){
        let record_id=null
        if(this.responseData.queryData.record_id)
        {
          record_id=this.responseData.queryData.record_id;
        }
        else if(this.responseData.record_id)
        {
          record_id=this.responseData.record_id
        }
        if(record_id!=null && (this.positive !=null || this.responseFeedback!=""))
        {
          let feedbackObj={
          model_request_id:record_id,
          rating: this.positive!=null? +this.positive:null,
          feedback: this.responseFeedback
          }
          this.$store.dispatch("responseFeedback",feedbackObj).then((result)=>{
              if(result.status=='success')  
              {
                return;
              }
            else if( result.message){
                console.error("Feedback Error: "+result.message)
              }
              else{
                console.error('Feedback Error: Could not update ')
              }
            }).catch((err)=>{
              if(err!="")
              {
                this.loginMsgStr=err;
                console.error('Feedback error: ',err)
              }
              else{
                console.error('Feedback Error: Could not update ')
              }
            });
        }
        else{
          console.warn('We have nothing to update')
        }
 
      },

      async copyOutput(){
        try{
          if(this.sourcesExist==true)
          {

            await navigator.clipboard.writeText(this.formatResponse(this.responseData.response)+"\n"+this.responseData.queryData.sources.join("\n"));
          }
          else{
            await navigator.clipboard.writeText(this.formatResponse(this.responseData.response));
          }
          this.copyStatus="Copied";
          this.copyIcon=this.checkmarkIcon;
        }
        catch($e){
          console.error('Cannot copy with the error',$e)
          this.copyStatus='Error: unable to copy'
          this.copyIcon=this.errorIcon;
        }
        finally{
          this.resetIconsAndStatus();
        }
      },

      resetIconsAndStatus(){
        setTimeout(()=>{  
            this.copyStatus="Copy"
            this.copyIcon=this.defaultCopyIcon;
            this.emailIcon=this.defaultEmailIcon
          },1000)
      },

      createEmailLink(){
        try{
        var encodedSubject = this.responseData.queryData.project?encodeURIComponent(this.responseData.queryData.project):'';
        var encodedBody = encodeURIComponent(this.formatResponse(this.responseData.response));

        var mailToUrl = 'mailto:?subject=' + encodedSubject + '&body=' + encodedBody;
        this.emailIcon=this.checkmarkIcon;
        window.open(mailToUrl);
        }
        catch(e)
        {
          console.error('We had an error while launching the email link',e)
          this.emailIcon=this.errorIcon;
        }
        finally{
          this.resetIconsAndStatus();
        }
      },
      formatResponse(textToFormat)
      {
        return textToFormat.replace('f"""', "").replace('"""','').trim(); //Just used tp replace the tri
      },
      formatError(textToFormat)
      {
        return textToFormat.replace(/^Error:\s*/, '');
      }
    },
  };
  </script>
  
  <style lang="scss" scoped>
  @import "../assets/styles/main.scss";
  @import "./styles/ChatEntry.scss";
  @import "./styles/Buttons.scss";
  </style>
  