<template>
    <div id="userRequest">  
        <div class="user-icon">
            <font-awesome-icon :icon="['fas', 'user']" />
            <span> You</span>
        </div>
        <div class="information-section">
        <div v-if="requestData.queryType==0 && requestData.queryData.project"><b>Project:</b> {{requestData.queryData.project}}</div>
        <span><b v-if="requestData.queryType==0 && requestData.userQuery && requestData.queryData.project">Information:</b> {{requestData.userQuery}}</span>
        </div>
        <div class="loading-spinner" v-if="loading"><Spinner_2/></div>
        </div>
  </template>
  
  <script>
  import { mapState } from "vuex";
  import Spinner_2 from "./Spinner_2.vue";
  export default {
    name: "UserRequest",
    components: {
      Spinner_2,
    },
    props:{
        requestData:Object,
        requestType:Object
    },

    data() {
      return {
        loading:false,
      };
    },
    watch: {
      
    },


    computed: {
      ...mapState({
        requestTypes:(state)=>state.requestTypes,
      }),
      isSelected(){
        if(this.selected && this.selected.record_id && this.selected.record_id==this.requestData.record_id)
        {
            return true;
        }
        return false;
      },
    },
    methods: {
 
    },
  };
  </script>
  
  <style lang="scss" scoped>
  @import "../assets/styles/main.scss";
  @import "./styles/ChatEntry.scss"
  </style>
  